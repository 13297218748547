export default {
  //Base url local
  //  url: 'http://localhost:8091/',

  //  url: 'https://joyaldev.me/intz/',
   url: 'https://test.intaccount.co.in/api/',

  //Base url live
  // url: 'http://intz.live:8080/',

  //Base url live addOn
  //url: 'http://intnew.in:8091/',

  //Base url live addOn Test
  // url: 'http://intz.live:8091/',
};
